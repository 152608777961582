<template>
    <div class="sys-role">
        <page-title :title="'系统角色管理'">配置系统相关的角色</page-title>
        <el-button icon="el-icon-plus" type="success" size="small" @click="addDialog=true">新增角色</el-button>
        <div class="role-table">
            <el-table :data="roleData" size="mini" border style="width: 100%">
                <el-table-column type="index" label="序号" width="50" align="center">
                </el-table-column>
                <el-table-column prop="name" label="角色名称" align="center">
                </el-table-column>
                <el-table-column prop="code" label="角色编码" width="180" align="center">
                </el-table-column>
                <el-table-column prop="roleSort" label="排序" width="100" align="center">
                </el-table-column>
                <el-table-column prop="roleDesc" label="角色说明" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建日期" width="160" align="center">
                </el-table-column>
                <el-table-column label="操作" header-align="center" min-width="240" width="250" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning" @click="editRole(scope.row)">修改</el-button>
                        <el-button size="mini" type="danger" @click="deleteRole(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                small
                background
                layout="prev, pager, next"
                :total="1000"
                style="margin-top:15px;text-align: right;">
            </el-pagination>
        </div>
        <el-dialog title="新增角色" :visible.sync="addDialog" custom-class="my-dialog-style" @close="resetForm">
            <el-form ref="form" :model="form" size="small" label-width="100px" label-position="right">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="角色名称：" prop="name">
                            <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="角色编码：" prop="code">
                        <el-input v-model="form.code" placeholder="请输入角色编码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="6">
                            <el-form-item label="排序：" prop="roleSort">
                                <el-input v-model="form.roleSort" placeholder="排序"></el-input>
                            </el-form-item>
                    </el-col>
                    <el-col :span="18">
                            <el-form-item label="说明：" prop="roleDesc">
                                <el-input v-model="form.roleDesc" placeholder="请输入角色说明"></el-input>
                            </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="角色授权：" label-position="top" prop="checkedMenu">
                    <div class="menu-area">
                        <el-tree
                        :data="menuData"
                        show-checkbox
                        node-key="id"
                        :indent="32"
                        :props="{label:'menuName'}"
                        ref="menuTree"
                        default-expand-all
                        :expand-on-click-node="false"
                        :check-on-click-node="true"
                        :check-strictly="isMenuCheck">
                        </el-tree> 
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm" size="small">取 消</el-button>
                <el-button type="primary" @click="saveRole" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:"SysRole",
    data(){
        return{
            roleData:[],
            menuData:[],
            isMenuCheck:false,
            addDialog:false,//新增弹框处理
            form:{
                id:null,
                name:"",
                code:"",
                roleSort:1,
                roleDesc:"",
                checkedMenu:[],
            }
        }
    },
    created(){
        this.initPage();
        this.initMenuData();
    },
    methods:{
        /**初始化角色数据 */
        initPage(){
            const that = this;
            that.request.get("sysrole/page").then(res=>{
                that.roleData = res.data;
            })
        },
        /**初始化菜单数据 */
        initMenuData(){
            const that = this;
                that.request.get("sysmenu/list").then(res=>{
                that.menuData = res.data;
            });
        },
        /**保存新角色至数据库 */
        saveRole(){
            const that = this;
            that.form.checkedMenu = that.$refs.menuTree.getCheckedNodes(false,true).map(i => i.id);
            that.request.post("sysrole/save",that.form).then(res=>{
                if(res.code===200){
                    that.initPage();
                    that.addDialog  =false;
                    that.resetForm();
                }
            })
        },
        /**角色编辑:
         * 1、先根据表格的值进行表单赋值。
         * 2、再获取该角色已有的菜单资源进行菜单赋值。
        */
        editRole(row){
            const that = this;
            that.addDialog = true;
            that.form = JSON.parse(JSON.stringify(row));
            that.request.get("sysrole/getMenuByRole",{roleid:row.id}).then(res=>{
                let checkedMenu = res.data;
                that.isMenuCheck = true;
                that.$nextTick(()=>{
                    that.$refs.menuTree.setCheckedKeys(checkedMenu);
                    that.isMenuCheck = false;
                });
            });
        }, 
        /**角色删除 */
        deleteRole(roleid){
            const that = this;
            that.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("sysrole/delete",{roleid:roleid}).then(res=>{
                    if(res.code===200){
                        that.initPage();
                    }
                });
            }).catch({});
        },
        /**表单初始化处理 */
        resetForm(){
            const that  =this;
            Object.keys(that.form).forEach(key=>(
                that.$delete(that.form,key)
            ));
            that.addDialog = false;
            that.$refs.menuTree.setCheckedKeys([]);
        }
    }
}
</script>
<style lang="less" scoped>
.sys-role{
    .role-table{
        margin-top:15px;
    }
    .menu-area{
        padding: 10px;
        border: 1px solid #e3e3e3;
        max-height: 300px;
        overflow-y: auto;
        min-width: 200px;
        .el-tree{
            display: flex;
            flex-wrap: wrap;
        }
    }
}
</style>